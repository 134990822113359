<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full relative">
            <a-affix>
                <div class="top-0 left-3 right-3 z-10 bg-white py-3 border-b border-gray-200">
                    <div class="flex items-center gap-3 mb-3">
                        <a-select v-model:value="supplier" show-search style="width: 250px" :options="suppliers" placeholder="Select Supplier..." />
                        <a-button :disabled="!supplier" @click="downloadCSV">Download CSV</a-button>

                        <div class="flex items-center gap-3 ml-auto">
                            <a-range-picker v-model:value="dates" format="DD/MM/YYYY" :ranges="ranges" />
                        </div>
                    </div>
                </div>
            </a-affix>

            <div class="pt-4">
                <DxDataGrid ref="table" :data-source="datasource" :remote-operations="false" :column-auto-width="true" :allow-column-reordering="true" :paginate="false" :allow-column-resizing="true" :row-alternation-enabled="true" :show-borders="true">
                    <DxColumn data-field="SKU" caption="SKU" data-type="string" :width="100" />
                    <DxColumn data-field="ItemTitle" caption="Item Title" data-type="string" :width="200" />
                    <DxColumn data-field="Available" caption="Available" data-type="number" :width="100" />
                    <DxColumn data-field="In Open Orders" caption="In Open Orders" data-type="number" :width="130" />
                    <DxColumn data-field="Level" caption="Level" data-type="number" :width="100" />
                    <DxColumn data-field="Min Level" caption="Min Level" data-type="number" :width="100" />
                    <DxColumn data-field="Due" caption="Due" data-type="number" />
                    <DxColumn data-field="Req Qty" caption="Req Qty" data-type="number" />
                    <DxColumn data-field="SupplierName" caption="Supplier Name" data-type="string" />
                    <DxColumn data-field="SupplierCode" caption="Supplier Code" data-type="string" />
                    <DxColumn data-field="SupplierBarcode" caption="Supplier Barcode" data-type="string" />
                    <DxColumn data-field="LeadTime" caption="Lead Time" data-type="number" />
                    <DxColumn data-field="KnownPurchasePrice" caption="Known Purchase Price" data-type="number" :format="{ style: 'currency', currency: 'GBP', useGrouping: true }" alignment="right" />
                    <DxColumn data-field="MinOrder" caption="Min Order" data-type="number" />
                    <DxColumn data-field="PackSize" caption="Pack Size" data-type="number" />
                    <DxColumn caption="Consumption" cell-template="consumptionCellTemplate" />
                    <template #consumptionCellTemplate="{ data: cellData }">
                        <a-button :loading="cellData.data.loading" :disabled="!dates" @click="getConsumption(cellData)">Get Consumption</a-button>
                    </template>
                    <DxFilterRow :visible="true" />
                    <DxHeaderFilter :visible="true" />
                    <DxGroupPanel :visible="true" />
                    <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
                    <DxGrouping :auto-expand-all="false" />
                    <DxPaging :enabled="false" />
                </DxDataGrid>
            </div>

            <a-modal v-model:visible="modalVisible" title="Consumption" width="70%" :closable="false" :mask-closable="true">
                <div v-if="currentConsumption.sku">
                    <h3 class="text-lg font-bold mb-3">{{ currentConsumption.sku }}</h3>
                    <table class="w-full border border-gray-200">
                        <thead>
                        <th class="px-2 py-1 border-b border-gray-200 border-r">Date</th>
                        <th class="px-2 py-1 border-b border-gray-200 border-r">Stock Quantity (Total: {{ getTotalUsed() }})</th>
                        <th class="px-2 py-1 border-b border-gray-200 border-r">Shipped (Total: {{ getTotalShipped() }})</th>
                        <th class="px-2 py-1 border-b border-gray-200">Stock Value</th>
                        </thead>

                        <tbody>
                        <tr v-for="stock in currentConsumption.items" :key="stock.Date">
                            <td class="px-2 py-1 border-b border-gray-200 border-r">{{ formatDate(stock.Date) }}</td>
                            <td class="px-2 py-1 border-b border-gray-200 border-r">{{ stock.StockQuantity }}</td>
                            <td class="px-2 py-1 border-b border-gray-200 border-r">{{ stock.Shipped }}</td>
                            <td class="px-2 py-1 border-b border-gray-200">{{ Math.ceil(stock.StockValue) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <template #footer>
                    <a-button key="back" @click="closeModal">Ok</a-button>
                </template>
            </a-modal>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import {
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxPaging,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter
} from 'devextreme-vue/data-grid';
import moment from 'moment';
export default defineComponent({
    name: "LowStock",
    components: {
        DxDataGrid,
        DxColumn,
        DxGrouping,
        DxGroupPanel,
        DxPaging,
        DxSearchPanel,
        DxFilterRow,
        DxHeaderFilter
    },
    data() {
        return {
            datasource: [],
            pageSizes: [10, 25, 50, 100],
            suppliers: [],
            supplier: null,
            dates: null,
            modalVisible: false,
            ranges: {
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
            },
            currentConsumption: {
                sku: null,
                items: []
            }
        }
    },
    watch: {
        modalVisible(val, old_val) {
            if(old_val === true && val === false) {
                this.currentConsumption.sku = null;
                this.currentConsumption.items = [];
            }
        }
    },
    mounted() {
        this.getSuppliers();
        this.getData();
    },
    methods: {
        getData() {
            this.$http.get('https://api.darkside-developments.com/api/dispatch/lowstock').then((resp) => {
                if (resp.data.success) {
                    this.datasource = resp.data.data
                }
            })
        },
        getSuppliers() {
            this.$http.get('https://api.darkside-developments.com/api/suppliers/all').then((resp) => {
                resp.data.forEach(el => {
                    const suppName = el.SupplierName;
                    this.suppliers.push({value: suppName, label: suppName})
                })
            })
        },
        downloadCSV() {
            this.$http({
                url: 'https://api.darkside-developments.com/api/dispatch/lowstock/supplier?supplier=' + this.supplier,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.supplier.replace(' ', '_') + '.csv');
                document.body.appendChild(link);
                link.click();
            });
        },
        getConsumption(e) {
            e.data.loading = true;
            this.$http.post('https://api.darkside-developments.com/api/dispatch/lowstock/consumption', {
                sku: e.data.SKU,
                dates: this.dates
            }).then((resp) => {
                if(resp.data.length === 0) {
                    this.$message.info('No consumption data found for ' + e.data.SKU);
                } else {
                    this.currentConsumption.sku = e.data.SKU;
                    this.currentConsumption.items = resp.data;
                    this.modalVisible = true;
                }
                e.data.loading = false;
            }).catch(err => {
                console.log(err)
                e.data.loading = false;
            })
        },
        getTotalShipped() {
            let total = 0;
            this.currentConsumption.items.forEach(el => {
                total += el.Shipped;
            });
            return total;
        },
        getTotalUsed() {
            let total = 0;
            this.currentConsumption.items.forEach(el => {
                total += el.StockQuantity;
            });
            return total;
        },
        formatDate(val) {
            return moment(val).format('DD-MM-YYYY')
        },
        closeModal() {
            this.modalVisible = false;
            this.currentConsumption.sku = null;
            this.currentConsumption.items = [];
        }
    },
});
</script>

<style lang="scss">
.ant-message-custom-content.ant-message-info {
    display: flex;
    align-items: center;
}
</style>